import React, { ReactNode } from "react";
import { Col, Image, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import * as Icons from "react-bootstrap-icons";
import { RouteComponentProps } from "react-router-dom";

import * as utils from "../utils/Utils";
import * as constants from "../constants";
import MetadataModal from "./MetadataModal";
import MetadataPopover from "./MetadataPopover";
import moment from "moment";
import { getPromotions } from "../utils/Requests";
import Branding from "../config/Branding";
import InfoIcon from "./InfoIcon";

const ResultsContainer = styled.div`
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    border-radius: 3px;
`;

const ResultsTitle = styled.div`
    text-align: center;
    font-size: 12px;
    @media (min-width: 768px) {
        max-width: 200px;
    }
`;

const ResultsText = styled.div`
    overflow-wrap: break-word;
    text-align: center;
    padding-bottom: 10px;
    line-height: normal;
    font-size: 12px;
    @media (min-width: 768px) {
        max-width: 200px;
    }
`;

const MessageContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const CornerSelect = styled.div`
    position: absolute;
    top: 0;
    left: 14px;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: ${Branding.draggableBackground} transparent transparent transparent;
`;

const CornerSelected = styled.div`
    position: absolute;
    top: 0;
    left: 14px;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: ${Branding.darkGreen} transparent transparent transparent;
`;

const CornerCheck = styled(Icons.CheckCircle)`
    color: white;
    top: -45px;
    position: relative;
    left: 5px;
`;

const CornerCircle = styled(Icons.Circle)`
    color: white;
    top: -45px;
    position: relative;
    left: 5px;
`;

const LiveIndicator = styled.div`
    position: absolute;
    top: 3px;
    left: 20px;
    z-index: 10;
`;

const ScoreIndicator = styled.div`
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 10;
    opacity: 95%;
    border-radius: 3px;
    padding: 2px;
    color: white;
    background: gray;
    box-shadow: 0px 0px 15px gray;
`;

const StarIcon = styled(Icons.StarFill)`
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 2;
    color: ${Branding.yellow};
    font-size: 18px;
    filter: drop-shadow(2px 4px 6px black);
`;

const RelativeDiv = styled.div`
    position: relative;
`;

type HistoryObject = RouteComponentProps["history"]

type SearchResultsProperties = {
    customer: CustomerConfig | undefined,
    showMetadataLink?: boolean,
    showOverlay?: boolean,
    items: Array<any>,
    username: string,
    groups: string[],
    clickToSelect?: boolean
    promotedItems?: Array<Record<string, any>>,
    selectCallback?: (item: Record<string, any>) => void,
    smallImages?: boolean,
    slotId?: string,
    history?: HistoryObject

}

type PromotionData = {
    metadataId: string,
    promotionId: string;
    promotionName: string;
    endDate: string;
};

type SearchResultsState = {
    showModal: boolean,
    selectedItem: Record<string, any>,
    mltItems: Array<any>,
    promotedItems: Record<string, PromotionData>;
    promotions: Array<Record<string,any>>
}

export class SearchResults extends React.Component<SearchResultsProperties, SearchResultsState> {
    public static defaultProps = {
        showOverlay: true
    };


    constructor(props: SearchResultsProperties) {
        super(props);
        this.state = {
            showModal: false,
            selectedItem: {},
            mltItems: [],
            promotedItems: {},
            promotions: []
        };
    }

    public componentDidMount(): void {
        this.getPromotions().then(this.renderItems);
    }

    private async getPromotions(): Promise<void> {
        if (this.props.customer) {
            const promotions = await getPromotions(this.props.customer);

            this.setState({
                promotions
            });
        }
    }

    private renderItems = (): void => {
        if (this.props.items) {
            this.props.items.forEach((item: any) => {
                this.updatePromotedItems(item);
            });
        }
    }

    public async reset(): Promise<void> {
        this.setState({
            showModal: false,
            selectedItem: {},
            mltItems: []
        });
    }

    private updatePromotedItems = (item: any) => {
        if (this.props.slotId) {
            const promotionData = utils.isPromotedItem(item, this.props.slotId, this.state.promotions);

            if (promotionData) {
                this.setState((prevState) => ({
                    promotedItems: {
                        ...prevState.promotedItems,
                        [item.id]: promotionData
                    }
                }));
            }
        }
    };

    private navigateToPromotions = (event: any) => {
        event.preventDefault();

        if (this.props.history) {
            this.props.history.push(`/promotions?customer=${this.props.customer?.name}`);
        }
    };

    private getResult = (item: any): ReactNode => {
        const clickToSelect = this.props.clickToSelect || false;
        let selected = false;

        if (this.props.promotedItems) {
            selected = this.props.promotedItems.findIndex((promo) => {
                return promo.id === item.id;
            }) !== -1;
        }

        const promotionData = this.state.promotedItems[item.id];

        const promotionPopover = (
            <Popover id="promotion-popover">
                <Popover.Title as="h3">Promotion Details</Popover.Title>
                <Popover.Content>
                    <strong>Promotion ID:</strong> {promotionData?.promotionId}<br />
                    <strong>Promotion Name:</strong> {promotionData?.promotionName}<br />
                    <strong>End Date:</strong> {moment(promotionData?.endDate).utc().format(constants.DATE_FORMAT)}
                </Popover.Content>
            </Popover>
        );

        return (
            <div onClick={() => { this.selectItem(item); }}>
                {this.props.customer && this.props.showMetadataLink &&
                    <InfoIcon
                        customer={this.props.customer}
                        itemId={item["id"]}
                        openInNewTab
                        search
                    />
                }
                <LiveIndicator>{utils.isLiveItem(item)}</LiveIndicator>
                {item["score"] &&
                    <OverlayTrigger
                        placement={"auto"}
                        overlay={<Tooltip id="score-tooltip">Recommendation Score</Tooltip>}>
                        <ScoreIndicator>{(item["score"] * 100).toFixed(2)}</ScoreIndicator>
                    </OverlayTrigger>
                }
                <RelativeDiv>
                    {promotionData && (
                        <OverlayTrigger
                            placement="top"
                            overlay={promotionPopover}
                        >
                            <StarIcon onClick={this.navigateToPromotions} />
                        </OverlayTrigger>
                    )}
                    {this.props.showOverlay ? (
                        <OverlayTrigger
                            key={item["id"]}
                            placement={"auto"}
                            overlay={<MetadataPopover item={item} />}
                        >
                            <Image
                                src={utils.getImageUrl(item)}
                                className="img-fluid"
                                alt={item["id"]}
                                onError={(event) => {
                                    utils.getPlaceholderImage(event, item);
                                    if (this.props.smallImages && this.props.customer?.portraitImages && event.currentTarget.parentElement?.parentElement) {
                                        event.currentTarget.parentElement.parentElement.style.background = Branding.darkBackground;
                                    }
                                }}
                                rounded
                                style={this.props.customer && (this.props.smallImages && this.props.customer?.portraitImages) ? { maxWidth: "125px", maxHeight: "175px" } : {}
                                }
                            />
                        </OverlayTrigger>
                    ) : (
                        <Image
                            src={utils.getImageUrl(item)}
                            className="img-fluid"
                            alt={item["id"]}
                            onError={(event) => {
                                utils.getPlaceholderImage(event, item);
                                if (this.props.smallImages && this.props.customer?.portraitImages && event.currentTarget.parentElement?.parentElement) {
                                    event.currentTarget.parentElement.parentElement.style.background = Branding.darkBackground;
                                }
                            }}
                            rounded
                            style={this.props.customer && (this.props.smallImages && this.props.customer?.portraitImages) ? { maxWidth: "125px", maxHeight: "175px" } : {}
                            }
                        />
                    )}
                </RelativeDiv>
                {this.props.selectCallback && !clickToSelect && selected && <CornerSelected><CornerCheck size={20} /></CornerSelected>}
                {this.props.selectCallback && !clickToSelect && !selected && <CornerSelect><CornerCircle size={20} /></CornerSelect>}
            </div>
        );
    }

    private selectItem(item: Record<string, any>): void {
        if (this.props.selectCallback) {
            this.props.selectCallback(item);
        }
    }

    private displayModal(show: boolean, item: Record<string, any> = {}): void {
        this.setState({
            showModal: show,
            selectedItem: item
        });
    }

    private getResultsContainerStyle(): Record<string, any> {
        const customer = this.props.customer;
        if (this.props.smallImages && customer?.portraitImages) {
            return {
                width: "125px",
                height: "175px"
            };
        } else {
            return {
                width: "200px"
            };
        }
    }

    public render(): ReactNode {
        return (
            <>
                <MetadataModal
                    item={this.state.selectedItem}
                    customer={this.props.customer}
                    showModal={this.state.showModal}
                    closeCallback={() => { this.displayModal(false); }}
                    username={this.props.username}
                    groups={this.props.groups}
                />
                <Row className="justify-content-center">
                    {this.props.items && this.props.items.length > 0 &&
                        (this.props.items
                            .map((item: any, i: number) => {
                                const genres: Array<string> = utils.getGenres(item);

                                return (
                                    <Col key={i} md={{ span: "auto" }}>
                                        <ResultsContainer
                                            style={this.getResultsContainerStyle()}
                                            onClick={() => {
                                                if (!this.props.selectCallback) {
                                                    this.displayModal(true, item);
                                                }
                                            }}
                                        >
                                            {this.getResult(item)}
                                        </ResultsContainer>
                                        <ResultsTitle style={this.props.smallImages && this.props.customer?.portraitImages ? { width: "125px" } : {}}>
                                            <b>{item["name"]}</b>
                                        </ResultsTitle>
                                        <ResultsText style={this.props.smallImages && this.props.customer?.portraitImages ? { width: "125px" } : {}}>
                                            {item["typeName"]}
                                            <br />
                                            {genres.length > 0 &&
                                                <div>{genres.join(" - ")}</div>}
                                        </ResultsText>
                                    </Col>
                                );
                            })
                        )
                    }
                    {
                        this.props.items.length === 0 && (
                            <MessageContainer>
                                <h5>No Results!</h5>
                            </MessageContainer>
                        )
                    }
                </Row>
            </>
        );
    }
}