import React, { ReactNode } from "react";
import { Button, Col, Container, Form, Image, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Bookmark as PresetIcon, BookmarkStar as ModelIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import Branding from "../config/Branding";
import { SearchResults } from "../components/SearchResults";
import { getAvailablePresets } from "../config/Presets";
import { createRequest, getHostURL, getItemMetadata, getMetadataURL, getModelDomainInformation, makeSlotRequest } from "../utils/Requests";
import * as utils from "../utils/Utils";
import * as constants from "../constants";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import SeedAndUserHistoryPanel from "../components/SeedAndUserHistoryPanel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SeedInfo from "../components/SeedInfo";

const CentralCol = styled(Col)`
    text-align: center;
`;

const ResultsCol = styled(Col)`
    top: 10px;
    text-align:center;
`;

const SectionHeader = styled.h5`
    margin-top: 10px;
    text-align: center;
    font-size: 1.2rem;
`;

const ScaledImage = styled(Image)`
    height: 60px;
    width: unset;
    max-width: 240px;
    margin-top: 8px;
    margin-bottom: 8px;
`;

const SlotsViewerMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const SlotsViewerFormRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -5px;
`;

const SlotsViewerElementRow = styled(Row)`
    margin-bottom: 5px;
`;

const FieldName = styled(InputGroup.Text)`

`;

const SlotsViewerFormCol = styled(Col)`
    padding-left: 0 !important;
`;

type SlotsViewerState = {
    availablePresets?: Preset[],
    availableModelInfo?: Array<Record<string, any>>,
    preset?: Preset,
    selectedModel?: Record<string, any>,
    selectedModelIndex: number,
    userId: string,
    seedId: string | undefined,
    bywSeedId: string | undefined,
    items: Array<Record<string, any>>,
    error: Error | null,
    isLoaded: boolean
    isLoading: boolean,
    presetNumber: number,
    seedItem?: Record<string, any>,
    showSeedAndHistoryPanel: boolean
}

type SlotsViewerProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string
    customerConfig: CustomerConfig,
    modelViewer?: boolean
}

interface SlotsViewerProperties extends SlotsViewerProps, RouteComponentProps { }

class SlotsViewer extends React.Component<SlotsViewerProperties, SlotsViewerState> {

    private timeoutId: number | null;

    constructor(props: SlotsViewerProperties) {
        super(props);
        this.timeoutId = null;
        const availablePresets = this.getPresets();

        this.state = {
            availablePresets,
            preset: this.props.modelViewer ? undefined : availablePresets[0],
            presetNumber: 0,
            userId: this.props.modelViewer ? "" : availablePresets[0].userId,
            seedId: this.props.modelViewer ? "" : availablePresets[0].seedId,
            bywSeedId: undefined,
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false,
            showSeedAndHistoryPanel: false,
            selectedModelIndex: 0
        };
    }

    public componentDidMount(): void {
        if (this.props.modelViewer) {
            this.requestModelDomainInfo();
        } else {
            this.update();
        }
    }

    private async requestModelDomainInfo(): Promise<void> {
        await getModelDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availableModelInfo: response,
                selectedModelIndex: 0,
                selectedModel: response[0]
            });
        });
    }

    private getPresets(): Preset[] {
        return getAvailablePresets(this.props.groups, false);
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, () => {
            this.requestItems();
            if (this.state.seedId) this.getSeedInfo();
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private getUrl = () => {
        if (this.state.selectedModel) {
            let modelRequestURL = this.state.selectedModel["slot_url"].replace("{user_id}", this.state.userId).replace("{seed_id}", this.state.seedId);
            modelRequestURL += "&_debug_metadata=true&ignore=portal";

            // Handle edge-case where the format is saved on the URL
            if (modelRequestURL.indexOf("format=rtve&") !== -1) {
                modelRequestURL = modelRequestURL.replace("format=rtve&", "");
            }

            return modelRequestURL;
        } else if (this.state.preset) {
            const preset = this.state.preset;
            let url = `${getHostURL(this.props.customerConfig)}/slots/${preset.slotId}/items`;

            const formattedParameters = utils.getFormattedParameters(preset.parameters);
            url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.state.userId.trim())}`;
            url += formattedParameters;

            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);

            if (this.state.seedId) {
                url += `&seedIds=${this.state.seedId}`;
            }

            return url;
        } else {
            return "";
        }
    }

    private async requestItems(): Promise<void> {
        await makeSlotRequest(this.getUrl())
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            let items = result.items;
                            let bywSeedId: string | undefined = "";
                            if (result.modelInfo) {
                                if (result.modelInfo.seed) {
                                    bywSeedId = result.modelInfo.seed.id;
                                }
                            }
                            if (!this.props.modelViewer && this.state.preset!.lookupItems) {
                                const metadataRequest = await createRequest(
                                    getMetadataURL(this.props.customerConfig),
                                    "POST",
                                    JSON.stringify(items)
                                );

                                items = await fetch(metadataRequest).then((response) => {
                                    if (response.ok) {
                                        return response.json().then((result) => {
                                            return result.items;
                                        });
                                    } else {
                                        return Promise.reject(response);
                                    }
                                });
                            }

                            this.setState({
                                isLoading: false,
                                isLoaded: true,
                                items,
                                error: null,
                                bywSeedId
                            }, this.getSeedInfo);
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            seedId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setPresets = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.state.availablePresets) {
            const preset: Preset = this.state.availablePresets[parseInt(event.target.value)];

            this.setState({
                preset,
                presetNumber: parseInt(event.target.value),
                userId: preset.userId,
                seedId: preset.seedId,
                bywSeedId: "",
                items: [],
                isLoading: false,
                isLoaded: false,
                error: null,
                seedItem: undefined
            }, this.update);
        }
    }

    private setModel = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.state.availableModelInfo) {
            const model: Record<string, any> = this.state.availableModelInfo[parseInt(event.target.value)];

            const userId = this.state.userId;
            let seedId = this.state.seedId;

            // If required, add further logic on model_type here
            switch (model.model_type) {
                case "byw":
                    seedId = "";
                    break;
                default:
                    break;
            }

            this.setState({
                selectedModel: model,
                selectedModelIndex: parseInt(event.target.value),
                userId,
                seedId,
                bywSeedId: "",
                items: [],
                isLoading: false,
                isLoaded: false,
                error: null,
                seedItem: undefined
            }, this.update);
        }
    }

    private renderModelInfo(): ReactNode {
        if (this.state.selectedModel) {
            const model = this.state.selectedModel;

            const variant = utils.unCamelCaseString(model.variant.replace("variant=", ""));
            const overlay = utils.unCamelCaseString(model.overlay.replace("overlay=", ""));

            const additionalFilters: Array<ReactNode> = [];

            if (model.genre) {
                const genres: Array<string> = model.genre.map((element: Record<string, any>) => {return `${element["label"]}`;});
                additionalFilters.push(<div><b>Genre</b>: {genres.join(",")}</div>);
            }

            if (model.typename) {
                let typeNames: Array<string> = [];

                if (typeof model.typename === "string") {
                    typeNames = [model.typename];
                } else {
                    typeNames = model.typename.map((element: Record<string, any>) => {return `${element["label"]}`;});
                }
                additionalFilters.push(<div><b>TypeName</b>: {typeNames.join(",")}</div>);
            }

            if (model.excludeGenre) {
                const excludeGenres = model.excludeGenre.map((element: Record<string, any>) => {return `${element["label"]}`;});
                additionalFilters.push(<div><b>Excluded Genre</b>: {excludeGenres.join(",")}</div>);
            }

            if (model.ageRating) {
                const ageRatings = model.ageRating.map((element: Record<string, any>) => {return `${element["label"]}`;});
                additionalFilters.push(<div><b>Age Rating</b>: {ageRatings.join(",")}</div>);
            }

            if (model.channelId) {
                const channelIds: Array<string> = model.channelId.flatMap((element: Record<string, any>) => {return `${element["label"]}`;});
                additionalFilters.push(<div><b>Channel Id</b>: {channelIds.join(",")}</div>);
            }

            if (model.programTypeId) {
                const programTypes = model.programTypeId.map((element: Record<string, any>) => {return `${element["label"]}`;});
                additionalFilters.push(<div><b>Program Type ID</b>: {programTypes.join(",")}</div>);
            }

            return <>
                <div><b>Variant</b>: {variant}</div>
                <div><b>Overlay</b>: {overlay}</div>
                {additionalFilters}
            </>;
        }
    }

    private renderResults(): ReactNode {
        const { error, isLoading, isLoaded } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else if (this.state.preset) {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
                slotId={this.state.preset.slotId}
                history={this.props.history}
            />;
        } else if (this.state.selectedModel) {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
                slotId={this.state.selectedModel.slot_id}
                history={this.props.history}
            />;
        }
    }

    private getSeedInfo = async (): Promise<void> => {
        if (this.props.customerConfig && (this.state.seedId || this.state.bywSeedId)) {
            let lookupSeedId: string | undefined = "";
            if (this.state.bywSeedId) {
                // If this is set, it should take precedence
                lookupSeedId = this.state.bywSeedId;
            } else {
                lookupSeedId = this.state.seedId;
            }

            await getItemMetadata(this.props.customerConfig, lookupSeedId!).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        } else {
            this.setState({
                seedItem: undefined
            });
        }
    }

    private toggleSeedAndHistoryPanel = (): void => {
        this.setState({
            showSeedAndHistoryPanel: !this.state.showSeedAndHistoryPanel
        });
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100">
                <SlotsViewerMainRow className="justify-content-center" standalone={this.props.standalone ? 1 : 0}>
                    <Col md={3}>
                            <SlotsViewerFormRow className="justify-content-center">
                                <CentralCol>
                                    <Row className="justify-content-center">
                                        {this.props.customerConfig.logoLocation &&
                                        <ScaledImage
                                            src={this.props.customerConfig.logoLocation}
                                            onError={(event) => { utils.getErrorLogo(event); }}
                                        />}
                                    </Row>
                                    <Row className="justify-content-center">
                                        <b>{this.props.modelViewer ? "Model Viewer" : "Slots Viewer" }</b>
                                    </Row>
                                    <SlotsViewerElementRow>
                                        {!this.props.modelViewer && this.state.availablePresets && <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id="preset-tooltip">Preset</Tooltip>}
                                                >
                                                    <FieldName><PresetIcon /></FieldName>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-preset"
                                                title="Preset"
                                                size="sm"
                                                onChange={this.setPresets}
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={this.state.presetNumber}>
                                                {this.state.availablePresets.map((preset, i) => {
                                                    return (
                                                        <option value={i} key={i}>
                                                            {preset.display}
                                                        </option>
                                                    );
                                                })}

                                            </Form.Control>
                                        </InputGroup>}
                                        {this.props.modelViewer && this.state.availableModelInfo && <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id="preset-tooltip">Model</Tooltip>}
                                                >
                                                    <FieldName><ModelIcon /></FieldName>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-preset"
                                                title="Preset"
                                                size="sm"
                                                onChange={this.setModel}
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={this.state.selectedModelIndex}>
                                                {this.state.availableModelInfo.map((model, i) => {
                                                    return (
                                                        <option value={i} key={i}>
                                                            {model.playlist_name}
                                                        </option>
                                                    );
                                                })}

                                            </Form.Control>
                                        </InputGroup>}
                                    </SlotsViewerElementRow>
                                    <SlotsViewerElementRow>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.USER}
                                            small
                                            includeIcon
                                            initialValue={this.state.userId}
                                            onChangeCallback={this.setUserId}
                                            onKeyUpCallback={this.checkKey}
                                        />
                                    </SlotsViewerElementRow>
                                    <SlotsViewerElementRow>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.SEED}
                                            includeIcon
                                            small
                                            initialValue={this.state.seedId}
                                            onChangeCallback={this.setSeedId}
                                            onKeyUpCallback={this.checkKey}
                                            showName={false}
                                            clearable
                                        />
                                    </SlotsViewerElementRow>
                                    <SlotsViewerElementRow>
                                        <SlotsViewerFormCol style={{ textAlign: "right", paddingRight: "0px" }}>
                                            <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                Submit
                                            </Button>
                                        </SlotsViewerFormCol>
                                    </SlotsViewerElementRow>
                                </CentralCol>
                            </SlotsViewerFormRow>
                            <SlotsViewerFormRow>
                            {this.props.modelViewer && this.state.selectedModel &&
                                <Col>
                                    <Row style={{textAlign: "center"}}>
                                        <CentralCol>
                                            <SectionHeader>Model Info</SectionHeader>
                                        </CentralCol>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.renderModelInfo()}
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            </SlotsViewerFormRow>
                            <SlotsViewerFormRow>
                            {this.state.seedItem && !this.state.showSeedAndHistoryPanel &&
                                <Col>
                                    <Row style={{textAlign: "center"}}>
                                        <CentralCol>
                                            <SectionHeader>Seed Info</SectionHeader>
                                        </CentralCol>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SeedInfo
                                                customerConfig={this.props.customerConfig}
                                                seedItem={this.state.seedItem}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            </SlotsViewerFormRow>
                    </Col>
                    <ResultsCol md={9} className="justify-content-center" style={{ minHeight: "85vh" }}>
                        {this.renderResults()}
                    </ResultsCol>
                </SlotsViewerMainRow>
                <SeedAndUserHistoryPanel
                    customerConfig={this.props.customerConfig}
                    userId={this.state.userId}
                    seedItem={this.state.seedItem}
                    showPanel={this.state.showSeedAndHistoryPanel}
                    showHidePanelCallback={this.toggleSeedAndHistoryPanel}
                />
            </Container>
        );
    }
}

export default withRouter(SlotsViewer);