import React, { ReactNode, RefObject } from "react";
import { Button, Col, Container, Form, Image, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Trash as ClearIcon, Search as SearchIcon } from "react-bootstrap-icons";
import SpeechRecognition from "react-speech-recognition";
import styled from "styled-components";

import Branding from "../config/Branding";
import FilterDropdown from "../components/filters/FilterDropdown";
import RangeSlider from "../components/filters/RangeSlider";
import { SearchResults } from "../components/SearchResults";
import SpeechRec from "../components/SpeechRec";
import { getSearchPreset } from "../config/Presets";
import { FilterUITypes } from "../enums";
import * as utils from "../utils/Utils";
import { getSearchURL, makeSlotRequest } from "../utils/Requests";
import * as constants from "../constants";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import { RouteComponentProps, withRouter } from "react-router-dom";

const CentralCol = styled(Col)`
    text-align: center;
`;

const ImageCol = styled(Col)`
    margin-right: 20px;
`;

const ScaledImage = styled(Image)`
    height: unset;
    width: unset;
    max-width: 250px;
    max-height: 95px;
`;

const SearchMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const SearchFormRow = styled(Form.Row)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const SearchElementRow = styled(Form.Row)`
    margin-bottom: 5px;
`;

const SearchFilterRow = styled(Form.Row)`
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    min-height: 38px;
`;

const PopularTermsList = styled.ul`
    list-style-type: none;
    padding-left: 0;
    padding-top: 10px;
`;

const PopularTermsListItem = styled.li`
    cursor: pointer;
    color: ${Branding.greyBlue};
    padding-bottom: 10px;
    font-size: 18px;
`;

type SearchState = {
    searchPreset: Preset | undefined,
    userId: string,
    searchTerm: string,
    searchParameters: string,
    additionalParameters?: Record<string, string>,
    items: Array<Record<string, any>>,
    error: Error | null,
    isLoaded: boolean,
    isLoading: boolean,
    fullSearch: boolean
}

type SearchProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface SearchProperties extends SearchProps, RouteComponentProps { }

class Search extends React.Component<SearchProperties, SearchState> {

    private filterRefs: Array<RefObject<FilterDropdown | RangeSlider>>;
    private timeoutId: NodeJS.Timeout | null;

    constructor(props: SearchProperties) {
        super(props);
        this.filterRefs = [];
        this.timeoutId = null;

        // Get all available presets and filter based on customerConfig.name
        const availablePreset = getSearchPreset(this.props.customerConfig.slug);

        this.state = {
            searchPreset: availablePreset,
            userId: availablePreset?.userId || "",
            searchTerm: "",
            searchParameters: "",
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false,
            fullSearch: false
        };
    }

    private enableVoiceSearch(): boolean {
        if (this.props.groups.includes(constants.THE_FILTER_FULL_AUTH) && SpeechRecognition.browserSupportsSpeechRecognition) {
            return true;
        }
        return false;
    }

    private voiceSearch = (term: string): void => {
        this.setState({
            isLoading: true,
            isLoaded: false,
            searchTerm: term,
            searchParameters: "q:" + term
        }, this.requestItems);
    }

    private voiceReset = (): void => {
        this.reset();
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, this.requestItems);
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private getUrl = () => {
        let url = getSearchURL(this.props.customerConfig);

        const formattedParameters = utils.getFormattedParameters(this.state.searchParameters);
        const additionalParameters = this.getAdditionalParameters();
        url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.state.userId.trim())}`;
        url += formattedParameters + "&" + additionalParameters;
        url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);

        if (this.state.fullSearch) {
            url += "&full=true";
        }


        return encodeURI(url);
    }

    private getAdditionalParameters = () => {
        if (this.state.additionalParameters) {
            return Object.keys(this.state.additionalParameters)
                .map(key => {
                    const value = this.state.additionalParameters?.[key];
                    if (value) {
                        return `${key}=${value}`;
                    } else {
                        return "";
                    }
                })
                .join("&");
        }
        return "";
    }


    public async requestItems(): Promise<void> {
        await makeSlotRequest(this.getUrl())
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                isLoading: false,
                                isLoaded: true,
                                items: items || [],
                                error: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private reset = (): void => {
        this.setState({
            searchTerm: "",
            searchParameters: "",
            userId: this.state.searchPreset?.userId || "",
            additionalParameters: {},
            items: [],
            isLoading: false,
            isLoaded: false,
            error: null
        }, this.resetComponents);
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value,
            searchParameters: "q:" + event.currentTarget.value
        });
    }

    private setUserId = (callbackEvent: string, submit: boolean) => {
        this.setState({
            userId: callbackEvent
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private resetComponents = (): void => {
        this.filterRefs.forEach((filter) => {
            if (filter.current) filter.current.reset();
        });
    }

    private setQueryStringParam = (qsObject: Record<string, string>): void => {
        if (this.state.additionalParameters) {
            this.setState({
                additionalParameters: Object.assign(this.state.additionalParameters, qsObject)
            }, this.autoRefresh);
        } else {
            this.setState({
                additionalParameters: qsObject
            }, this.autoRefresh);
        }
    }

    private autoRefresh(): void {
        if (this.state.searchTerm) this.update();
    }

    private buildComponent(filter: DropdownFilter | RangeSliderFilter, i: number): ReactNode {
        switch (filter.type) {
            case FilterUITypes.Dropdown: {
                const ddFilterRef = React.createRef<FilterDropdown>();
                this.filterRefs.push(ddFilterRef);
                return (
                    <SearchFilterRow key={`filter-${i}`}>
                        <FilterDropdown
                            ref={ddFilterRef}
                            filter={filter as DropdownFilter}
                            callback={this.setQueryStringParam}
                        />
                    </SearchFilterRow>
                );
            }
            case FilterUITypes.RangeSlider: {
                const rsFilterRef = React.createRef<RangeSlider>();
                this.filterRefs.push(rsFilterRef);
                return (
                    <SearchFilterRow key={`filter-${i}`}>
                        <RangeSlider
                            ref={rsFilterRef}
                            filter={filter as RangeSliderFilter}
                            callback={this.setQueryStringParam}
                        />
                    </SearchFilterRow>
                );
            }
            case FilterUITypes.Slider: {
                return (<p>{filter.name} - Slider</p>);
            }
            default: {
                Logger.error("Unknown filter type");
                break;
            }
        }
    }

    private buildFilterComponents = () => {
        this.filterRefs = [];
        return this.state.searchPreset?.filters?.map((filter: DropdownFilter | RangeSliderFilter, i) => {
            return this.buildComponent(filter, i);
        });
    }

    private setPopularSearchTerm = (term: string): void => {
        this.setState({
            searchTerm: term,
            searchParameters: "q:" + term
        }, this.update);
    }

    private setFullSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            fullSearch: event.currentTarget.checked
        }, this.update);
    }

    private showFullSearch = (): boolean => {
        if ((this.props.customerConfig.slug === "epix" || this.props.customerConfig.slug === "uktv"
            || this.props.customerConfig.slug === "rtve") ||
            (!this.props.groups.includes(constants.THE_FILTER_FULL_AUTH)))
        {
            return false;
        }
        return true;
    }

    private renderSearchResults(): ReactNode {
        const { error, isLoading, isLoaded, items, searchPreset } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            if (items.length === 0 && searchPreset?.popularTerms && searchPreset?.popularTerms.length > 0) {
                return <>
                    <Row className="justify-content-center">
                        <h5>Popular Searches</h5>
                    </Row>
                    <Row className="justify-content-center">
                        <PopularTermsList>
                            {searchPreset.popularTerms.map((term, i) => {
                                return <PopularTermsListItem key={i} onClick={() => this.setPopularSearchTerm(term)}>
                                    {term}
                                </PopularTermsListItem>;
                            })}
                        </PopularTermsList>
                    </Row>
                </>;
            } else {
                return <Row className="justify-content-center">
                    Results will appear here
                </Row>;
            }
        } else {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
                history={this.props.history}
                slotId={this.props.customerConfig.searchSlot}
            />;
        }
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100">
                <SearchMainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                    <CentralCol md>
                        <Form className="SearchSettings">
                            <SearchFormRow className={`justify-content-center${this.props.customerConfig.name === "MGM+" ? "" : " d-flex align-items-center"}`}>
                                <ImageCol md={2} className="d-none d-sm-block my-auto">
                                    <Row className="justify-content-center">
                                        {this.props.customerConfig.logoLocation &&
                                        <ScaledImage
                                            src={this.props.customerConfig.logoLocation}
                                            onError={(event) => { utils.getErrorLogo(event); }}
                                        />}
                                    </Row>
                                </ImageCol>
                                <Col md={4}>
                                    <SearchElementRow>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            includeIcon
                                            type={constants.USER}
                                            small
                                            initialValue={this.state.userId}
                                            onChangeCallback={this.setUserId}
                                            onKeyUpCallback={this.checkKey}
                                        />
                                    </SearchElementRow>
                                    <SearchElementRow>
                                        <InputGroup>
                                            {this.enableVoiceSearch() &&
                                                <InputGroup.Prepend>
                                                    <SpeechRec searchCallback={this.voiceSearch} resetCallback={this.voiceReset} small />
                                                </InputGroup.Prepend>
                                            }
                                            <Form.Control
                                                id="searchQueryInput"
                                                type="string"
                                                size="sm"
                                                value={this.state.searchTerm}
                                                onChange={this.setSearchTerm}
                                                onKeyPress={this.checkKey}
                                                onKeyUp={this.checkBackspaceKey}
                                                placeholder="Search Terms, e.g. Title, Actor" />
                                            <InputGroup.Append>
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                                    <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                        <SearchIcon />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                                    <Button variant="dark" size="sm" type="button" onClick={this.reset}>
                                                        <ClearIcon />
                                                    </Button>
                                                </OverlayTrigger>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </SearchElementRow>
                                    {this.showFullSearch() && <Row>
                                        <Col>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                label="Use Full Search Index (includes inactive items)"
                                                checked={this.state.fullSearch}
                                                onChange={this.setFullSearch}
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>}
                                </Col>
                                {this.state.searchPreset?.filters && <>
                                    <Col md={{ span: 3, offset: 1 }} style={{ padding: "0px" }}>
                                        {this.buildFilterComponents()}
                                    </Col>
                                </>}
                            </SearchFormRow>
                        </Form>
                    </CentralCol>
                </SearchMainRow>
                <Row className="justify-content-center">
                    <CentralCol md>
                        {this.renderSearchResults()}
                    </CentralCol>
                </Row>
            </Container>
        );
    }
}

export default withRouter(Search);