import * as constants from "../constants";

const PageBuilderRoute = {
    path: "/pagebuilder",
    displayName: "Page Builder",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH
    ]
};

const ModelComparisonRoute = {
    path: "/modelcomparison",
    displayName: "Comparison",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH
    ]
};


const LaneBuilderRoute = {
    path: "/lanebuilder",
    displayName: "Lane Builder",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.UKTV_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH
    ]
};

const TesterRoute = {
    path: "/tester",
    displayName: "Tester",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH
    ],
    defaultForGroup: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH
    ]
};

// const PagesRoute = {
//     path: "/pages",
//     displayName: "Pages",
//     groups: [
//         constants.THE_FILTER_FULL_AUTH
//     ]
// };

const SlotsViewerRoute = {
    path: "/slots",
    displayName: "Slots Viewer",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.EPIX_FULL_AUTH,
        constants.UKTV_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ],
    defaultForGroup: [
        constants.UKTV_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ]
};

const FallbackViewerRoute = {
    path: "/fallbackviewer",
    displayName: "Fallback Viewer",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const SlotsEditorRoute = {
    path: "/slotseditor",
    displayName: "Slots Editor",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const ExperimentsRoute = {
    path: "/experiments",
    displayName: "Experiments",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const ModelConfigRoute = {
    path: "/modelconfig",
    displayName: "Configuration",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const SearchRoute = {
    path: "/search",
    displayName: "Search",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.EPIX_FULL_AUTH,
        constants.UKTV_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ],
    defaultForGroup: [
        constants.EPIX_FULL_AUTH
    ]
};

const MetadataRoute = {
    path: "/metadata",
    displayName: "Metadata",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ],
    hide: true
};

const EntitiesRoute = {
    path: "/entities",
    displayName: "Search",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH
    ]
};

const EntitiesDetailRoute = {
    path: "/entitiesdetail",
    displayName: "EntitiesDetail",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH
    ],
    hide: true
};

const MultiTaggingRoute = {
    path: "/multitagging",
    displayName: "Multi-Tagging",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const PromotionsRoute = {
    path: "/promotions",
    displayName: "Promotions",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ]
};

const BlockingRoute = {
    path: "/blocking",
    displayName: "Blocking",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.DEMO_FULL_AUTH,
        constants.TELENOR_FULL_AUTH,
        constants.WAOO_FULL_AUTH,
        constants.BACKSTAGE_DEMO_FULL_AUTH,
        constants.SLOVAK_TELEKOM_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ]
};

const AnalyticsRoute = {
    path: "/analytics",
    displayName: "Analytics",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ],
    hide: true
};

const BrowseViewRoute = {
    path: "/browseview",
    displayName: "Browse",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ]
};

const CollectionsRoute = {
    path: "/collections",
    displayName: "Collections",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const ModelManagerRoute = {
    path: "/modelmanager",
    displayName: "Model Manager",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ],
    defaultForGroup: [
        constants.RTVE_FULL_AUTH
    ]
};

const ModelViewerRoute = {
    path: "/modelviewer",
    displayName: "Model Viewer",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.RTVE_FULL_AUTH,
        constants.KAN_FULL_AUTH
    ]
};

const PageManagerRoute = {
    path: "/pagemanager",
    displayName: "Page Manager",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};


const SystemHealthRoute = {
    path: "/systemhealth",
    displayName: "System Health",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.RTVE_FULL_AUTH
    ]
};

const ChartsViewerRoute = {
    path: "/chartsviewer",
    displayName: "Charts",
    groups: [
        constants.THE_FILTER_FULL_AUTH,
        constants.RTVE_FULL_AUTH
    ]
};

const EventBridgeRoute = {
    path: "/eventbridge",
    displayName: "EventBridge",
    groups: [
        constants.THE_FILTER_FULL_AUTH
    ]
};

const FilterRouting: RouteConfig[] = [
    {
        path: "/entities",
        displayName: "Entities",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            EntitiesRoute,
            MultiTaggingRoute
        ]
    },
    SearchRoute,
    BrowseViewRoute,
    ChartsViewerRoute,
    {
        path: "/models",
        displayName: "Models",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            ModelConfigRoute,
            ModelManagerRoute,
            ModelViewerRoute,
            PageManagerRoute
        ]
    },
    {
        path: "/slots",
        displayName: "Slots",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            SlotsEditorRoute,
            SlotsViewerRoute,
            TesterRoute,
            ExperimentsRoute,
            ModelComparisonRoute,
            FallbackViewerRoute
        ]
    },
    // Removed as unsupported currently
    // PagesRoute,
    {
        path: "/rules",
        displayName: "Rules",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            PromotionsRoute,
            BlockingRoute
        ]
    },
    {
        path: "/builders",
        displayName: "Builders",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            LaneBuilderRoute,
            PageBuilderRoute,
            CollectionsRoute
        ]
    },
    {
        path: "/monitoring",
        displayName: "Monitoring",
        groups: [constants.THE_FILTER_FULL_AUTH],
        children: [
            SystemHealthRoute,
            EventBridgeRoute
        ]
    },
    AnalyticsRoute,
    MetadataRoute,
    EntitiesDetailRoute
];

const DemoRouting: RouteConfig[] = [
    TesterRoute,
    SlotsViewerRoute,
    LaneBuilderRoute,
    PageBuilderRoute,
    PromotionsRoute,
    BlockingRoute,
    {
        path: "/entities",
        displayName: "Entities",
        groups: [constants.DEMO_FULL_AUTH],
        children: [
            EntitiesRoute,
            MultiTaggingRoute
        ]
    },
    SearchRoute,
    BrowseViewRoute,
    MetadataRoute,
    EntitiesDetailRoute
];

const CustomerRouting: RouteConfig[] = [
    SlotsViewerRoute,
    LaneBuilderRoute,
    PromotionsRoute,
    BlockingRoute,
    SearchRoute,
    BrowseViewRoute,
    MetadataRoute
];

const CustomerPageRouting: RouteConfig[] =
[
    ModelManagerRoute,
    SlotsViewerRoute,
    LaneBuilderRoute,
    PromotionsRoute,
    BlockingRoute,
    SearchRoute,
    BrowseViewRoute,
    MetadataRoute,
    PageBuilderRoute
];

const RTVERouting: RouteConfig[] =
[
    {
        path: "/models",
        displayName: "Models",
        groups: [constants.RTVE_FULL_AUTH],
        children: [
            ModelManagerRoute,
            ModelViewerRoute
        ]
    },
    SlotsViewerRoute,
    ChartsViewerRoute,
    SearchRoute,
    BrowseViewRoute,
    PromotionsRoute,
    BlockingRoute,
    MetadataRoute,
    SystemHealthRoute
];

export function getRoutingForGroup(groups: string[]): RouteConfig[] {
    if (groups?.includes(constants.THE_FILTER_FULL_AUTH)) {
        return FilterRouting;
    }

    if (groups?.includes(constants.DEMO_FULL_AUTH)) {
        return DemoRouting;
    }

    if (groups?.includes(constants.WAOO_FULL_AUTH) || groups?.includes(constants.TELENOR_FULL_AUTH)
        || groups?.includes(constants.BACKSTAGE_DEMO_FULL_AUTH)
        || groups?.includes(constants.SLOVAK_TELEKOM_FULL_AUTH)
        || groups?.includes(constants.KAN_FULL_AUTH)

    ) {
        return CustomerPageRouting;
    }

    if (groups?.includes(constants.RTVE_FULL_AUTH)) {
        return RTVERouting;
    }

    return CustomerRouting;
}

export function getDefaultRoute(groups: string[]): string {
    if (groups.includes(constants.THE_FILTER_FULL_AUTH) || groups.includes(constants.DEMO_FULL_AUTH)) {
        return TesterRoute.path;
    }
    else if (
        groups.includes(constants.UKTV_FULL_AUTH) ||
        groups.includes(constants.KAN_FULL_AUTH)
    ) {
        return SlotsViewerRoute.path;
    }
    else if (groups.includes(constants.RTVE_FULL_AUTH)) {
        return ModelManagerRoute.path;
    }

    return SearchRoute.path;
}
